import axiosAccounting from '@/libs/axios-accounting'

// eslint-disable-next-line camelcase
const accountStatement = (id, start_date, end_date) => axiosAccounting.get(`/accountingReports/accountStatement/${id}/${start_date}/${end_date}`)
// eslint-disable-next-line camelcase
const accountMovement = (start_date, end_date) => axiosAccounting.get(`/accountingReports/movementRestriction/${start_date}/${end_date}`)
const trialBalance = () => axiosAccounting.get('/accountingReports/trialBalance/')
// eslint-disable-next-line camelcase
const balanceSheet = (start_date, end_date) => axiosAccounting.get(`/accountingReports/balanceSheet/${start_date}/${end_date}`)

export default {
  accountStatement,
  accountMovement,
  trialBalance,
  balanceSheet,
}
